<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <BackButton :handler="backToReports" />
      </v-col>
    </v-row>
    <div class="app mt-4">
      <div class="user-wrapper">
        <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
        >
          <v-row>
            <v-col cols="12">
              <div class="" style="background-color: rgba(17, 42, 70, 0.04)">
                <p class="pa-4 font-extrabold text-xl text-blue">Filters</p>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <v-autocomplete
                v-model="searchParams.venueServiceId"
                outlined
                background-color="#fff"
                placeholder="Services"
                item-value="id"
                item-text="name"
                return-object
                :items="venueServices"
                @change="venueServiceChange"
                class="q-autocomplete shadow-0"
                hide-details
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="searchParams.b2b_type_id"
                outlined
                background-color="#fff"
                placeholder="Type"
                item-value="id"
                item-text="name"
                return-object
                :items="b2bTypes"
                @change="venueServiceChange"
                class="q-autocomplete shadow-0"
                hide-details
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col md="2">
              <v-name-search
                label=""
                outlined
                v-model="searchParams.nameSearch"
                @updateCustomer="nameSearch"
                required
                :clearable="true"
                class-name="q-text-field shadow-0"
                :dense="true"
              ></v-name-search>
            </v-col>

            <v-col cols="2" style="padding-right: 0; max-width: 170px">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    outlined
                    background-color="#fff"
                    class="q-text-field shadow-0"
                    hide-details
                    dense
                    v-model="date1Formatted"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParams.from_date"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2" style="max-width: 170px">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    class="q-text-field shadow-0"
                    hide-details
                    dense
                    v-model="date2Formatted"
                    outlined
                    background-color="#fff"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParams.to_date"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="1">
              <v-flex xs1 class="ml-1">
                <v-btn color="#00b0af" height="40" dark @click="getReport"
                  >Go</v-btn
                >
              </v-flex>
            </v-col>
          </v-row>
          <div class="md-card md-theme-default rounded-5 shadow-0">
            <div class="search_customer">
              <div class="header_title pl-4 pr-4">
                <v-row>
                  <v-col md="4">Partners Report</v-col>

                  <v-col md="4"> </v-col>
                  <v-col md="4" class="text-right">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-if="
                            checkExportPermission(
                              $modules.reports.discount.slug
                            )
                          "
                          class="export-button mt-2"
                          elevation="0"
                          height="40"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <SvgIcon text="Export Report">
                            <template v-slot:icon>
                              <ExportIcon />
                            </template>
                          </SvgIcon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="reportDownload('pdf')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-pdf-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as PDF</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="reportDownload('excel')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-excel-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as Excel</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
            </div>

            <div class="md-card-content">
              <div>
                <div
                  class="md-content md-table md-theme-default table_borders"
                  table-header-color="orange"
                  value
                >
                  <table class="table table-striped header-fixed">
                    <thead class="md-card-header sticky shadow-0">
                      <tr>
                        <th class="md-table-head sticky">
                          <div
                            class="md-table-head-container md-ripple md-disabled text-center"
                          >
                            <div class="md-table-head-label">
                              From selective date
                            </div>
                          </div>
                        </th>
                        <th class="md-table-head sticky">
                          <div
                            class="md-table-head-container md-ripple md-disabled text-center"
                          >
                            <div class="md-table-head-label">
                              To selective date
                            </div>
                          </div>
                        </th>
                        <th class="md-table-head sticky">
                          <div
                            class="md-table-head-container md-ripple md-disabled text-center"
                          >
                            <div class="md-table-head-label">Account Name</div>
                          </div>
                        </th>
                        <th class="md-table-head sticky">
                          <div
                            class="md-table-head-container md-ripple md-disabled"
                          >
                            <div class="md-table-head-label text-center">
                              Total Booking
                            </div>
                          </div>
                        </th>
                        <th class="md-table-head sticky">
                          <div
                            class="md-table-head-container md-ripple md-disabled text-center"
                          >
                            <div class="md-table-head-label">
                              Total Guided Tour Booking
                            </div>
                          </div>
                        </th>
                        <th class="md-table-head sticky">
                          <div
                            class="md-table-head-container md-ripple md-disabled text-center"
                          >
                            <div class="md-table-head-label">
                              Total Ticket Scans
                            </div>
                          </div>
                        </th>
                        <th class="md-table-head sticky">
                          <div
                            class="md-table-head-container md-ripple md-disabled text-center"
                          >
                            <div class="md-table-head-label">
                              Total Revenue Guided Tour
                            </div>
                          </div>
                        </th>
                      </tr>
                    </thead>

                    <tbody
                      v-if="b2bReport.length > 0"
                      :set="
                        ((totalRevenue = 0),
                        (totalBookings = 0),
                        (totalGuidedTours = 0),
                        (totalTicketScans = 0))
                      "
                    >
                      <template v-for="(details, index) in b2bReport">
                        <tr :key="`obj_head_${index}`">
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{ searchParams.from_date | dateformat }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{ searchParams.to_date | dateformat }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{ details.customer_name }}
                            </div>
                          </td>
                          <td
                            class="md-table-cell"
                            :set="(totalBookings += details.total_bookings)"
                          >
                            <div class="md-table-cell-container text-center">
                              {{ details.total_bookings }}
                            </div>
                          </td>

                          <td
                            class="md-table-cell"
                            :set="
                              (totalGuidedTours += details.total_guided_tour)
                            "
                          >
                            <div class="md-table-cell-container text-center">
                              {{
                                details.total_guided_tour
                                  ? details.total_guided_tour
                                  : 0
                              }}
                            </div>
                          </td>
                          <td
                            class="md-table-cell"
                            :set="
                              (totalTicketScans += parseInt(
                                details.ticket_scans
                              ))
                            "
                          >
                            <div class="md-table-cell-container text-center">
                              {{ details.ticket_scans }}
                            </div>
                          </td>
                          <td
                            class="md-table-cell"
                            :set="(totalRevenue += details.revenue)"
                          >
                            <div class="md-table-cell-container text-center">
                              {{
                                details.revenue
                                  ? parseFloat(details.revenue).toFixed(2)
                                  : 0.0 | toCurrency
                              }}
                            </div>
                          </td>
                        </tr>
                      </template>
                      <tr
                        class="font-weight-black grand_total_bg sticky_bottom"
                      >
                        <td class="md-table-cell" colspan="3">
                          <div class="md-table-cell-container text-center">
                            <strong>GRAND TOTAL</strong>
                          </div>
                        </td>
                        <td class="md-table-cell">
                          <div class="md-table-cell-container text-center">
                            <strong> {{ totalBookings }}</strong>
                          </div>
                        </td>
                        <td class="md-table-cell">
                          <div class="md-table-cell-container text-center">
                            <strong> {{ totalGuidedTours }}</strong>
                          </div>
                        </td>
                        <td class="md-table-cell">
                          <div class="md-table-cell-container text-center">
                            <strong> {{ totalTicketScans }}</strong>
                          </div>
                        </td>
                        <td class="md-table-cell">
                          <div class="md-table-cell-container text-center">
                            <strong>
                              {{ currencyCode }}
                              {{
                                parseFloat(totalRevenue).toFixed(2) | toCurrency
                              }}</strong
                            >
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td :colspan="8">
                          <v-card-text class="pa-8 text-center">
                            <h3>No data found!</h3>
                          </v-card-text>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <v-pagination
                    class="mt-3 new-pagination"
                    v-model="page"
                    total-visible="7"
                    :length="totalPages"
                  ></v-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import BackButton from "@/components/Common/BackButton.vue";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ExportIcon from "@/assets/images/misc/export-icon.svg";

export default {
  components: { ExportIcon, SvgIcon, BackButton },
  data() {
    return {
      totalPages: 0,
      page: 1,
      b2bReport: [],
      menu1: null,
      menu2: null,
      fileName: null,
      searchParams: {
        from_date: moment().subtract(30, "days").format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
        venueServiceId: null,
        b2b_type_id: null,
      },
      containerHeight: 400,
    };
  },
  watch: {
    page: {
      immediate: true,
      handler() {
        this.getReport();
      },
    },
  },
  computed: {
    ...mapGetters({
      sportsCategory: "getSportsService",
    }),
    date1Formatted() {
      return moment(this.searchParams.from_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.to_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    b2bTypes() {
      return this.$store.getters.getB2bTypes.data;
    },
  },
  mounted() {
    this.onResize();
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.$store.getters.getB2bTypes.status == false) {
      this.$store.dispatch("loadB2bTypes");
    }
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.searchParams.facilityId.length == this.facilities.length) {
          this.searchParams.facilityId = [];
        } else {
          this.searchParams.facilityId = this.facilities.map((item) => item.id);
        }
      });
      setTimeout(() => {
        this.getReport();
      });
    },
    icon() {
      if (this.searchParams.facilityId.length == this.facilities)
        return "mdi-close-box";
      if (this.searchParams.facilityId.length == 0)
        return "mdi-checkbox-blank-outline";
      return "mdi-minus-box";
    },
    backToReports() {
      this.$router.push({
        name: "Reports",
      });
    },

    getFilterUrlData() {
      if (
        moment(this.searchParams.from_date, "YYYY-MM-DD").isAfter(
          moment(this.searchParams.to_date, "YYYY-MM-DD")
        )
      ) {
        this.showError("To date must be greater than or equal to from date");
        return;
      }
      let url = `?from_date=${this.searchParams.from_date}&to_date=${this.searchParams.to_date}`;
      this.fileName =
        this.searchParams.from_date + "-to-" + this.searchParams.to_date;
      let venueService = null;
      if (this.searchParams.venueServiceId) {
        url += `&venue_service_id=${this.searchParams.venueServiceId.venue_service_id}`;
        venueService = this.findValueByKey(
          this.venueServices,
          this.searchParams.venueServiceId.venue_service_id
        );
      }
      if (this.searchParams.b2b_type_id) {
        url += `&b2b_type_id=${this.searchParams.b2b_type_id.id}`;
      }
      if (this.searchParams.customer_id) {
        url += `&customer_id=${this.searchParams.customer_id}`;
      }

      if (this.searchParams.customer_name != undefined) {
        this.fileName += "-" + this.searchParams.customer_name;
        url += `&customer_name=${this.searchParams.customer_name}`;
      }
      if (venueService !== null) this.fileName += "-" + venueService.name;
      if (this.fileName != null)
        this.fileName = "Partners-Report-" + this.fileName.replace(/\s/g, "");
      else this.fileName = "Partners-Report";
      return url;
    },
    findValueByKey(opts, key) {
      var match = opts.filter(function (opt) {
        if (Array.isArray(key) == true) {
          return opt.id === key[0];
        } else {
          return opt.id === key;
        }
      });
      return match[0] ? match[0] : null;
    },

    getReport() {
      let url = this.getFilterUrlData();
      if (!url) return;
      this.showLoader("Loading");
      this.$http
        .get(`venues/reports/b2b-report${url}&page=${this.page}`)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data.data;
            this.b2bReport = data;
            this.totalPages = response.data.total_pages;
          }
          this.hideLoader();
        })
        .catch((error) => {
          this.errorChecker(error);
          this.hideLoader();
        });
    },

    reportDownload(report_type) {
      let link = "";
      if (report_type == "pdf") {
        link = "b2b-report-pdf";
      } else if (report_type == "excel") {
        link = "b2b-report";
      }
      let url = this.getFilterUrlData();
      if (!url) return;
      this.showLoader("Downloading report");
      this.$http
        .get(`venues/reports/` + link + `/download${url}`, {
          responseType: "blob",
        })
        .then((response) => {
          this.hideLoader();
          if (response.status == 200) {
            this.downloadReport(response, this.fileName, report_type);
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    onResize() {
      this.containerHeight = window.innerHeight - 380;
    },
    nameSearch(e) {
      this.searchParams.customer_id = e.customer_id;
      this.searchParams.customer_name = e.name;
      this.page = 1;
      this.getReport();
    },
    venueServiceChange() {
      // this.getFacilities();
      this.getReport();
    },
  },
};
</script>

<style scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #edf7f8;
  padding: 10px 4px;
}
.md-table table {
  overflow: scroll;
}
.md-card .md-card-header {
  z-index: 1000;
}
.orange-text {
  color: #ff9800 !important;
  caret-color: #ff9800 !important;
}
</style>
